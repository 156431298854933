<template>
  <div class="store">
    <div class="row margin-0">
      <div class="col-md-12">
        <div class="store-block">
          <div class="row margin-0 info-block">
            <div class="col-md-2 col-12 pad-top-10 text-center">
              <img :src="store&&store.image?image:'/noimage.jpg'" style="max-width: 20vh;margin-bottom:2vh;" alt="">
            </div>
            <div class="col pad-top-40">
              <h4 v-if="store">{{store.name}}</h4>
              <h4 v-else class="shine pad-10" style="max-width:300px;"></h4>
              <template v-if="store">
                <span v-if="store.data && store.data.address && store.data.address.city"><template v-if="store.data.address.city || store.data.address.state"><span class="fa fa-map-marker"></span></template> {{store.data.address.city}}<template v-if="store.data.address.city && store.data.address.state">, </template>{{store.data.address.state}} &nbsp;</span>
                <span v-else><span class="fa fa-map-marker"></span> Hyderabad, Telangana</span>
                <span v-if="store.mobile" class="d-block margin-top-5" style="font-size:1em;"> <span class="fa fa-phone"></span> 91 {{store.mobile}}</span>
                <span v-if="store.email" class="d-block margin-top-5" style="font-size:1em;"> <span class="fa fa-envelope"></span> {{store.email}}</span>
              </template>
              <span v-else class="shine pad-5 margin-top-15" style="max-width:300px;"></span>
            </div>
          </div>
          <div class="detail-block">
            <ul class="store-menu">
              <!-- <li @click="tab='about'" :class="{'active':tab==='about'}"><ion-icon name="business"></ion-icon> About</li> -->
              <li @click="tab='products'" :class="{'active':tab==='products'}"><ion-icon name="list"></ion-icon> Listings</li>
              <li @click="tab='contact'" :class="{'active':tab==='contact'}"><ion-icon name="call"></ion-icon> Contact</li>
            </ul>
            <div class="pad-15 margin-bottom-20" v-if="tab==='products'">
              <ul class="ads-list margin-bottom-20">
                <template v-if="loading_list">
                  <template v-for="index in 8">
                    <ad-loading :key="index"></ad-loading>
                  </template>
                </template>
                <template v-else-if="products && products">
                  <template v-for="(item, index) in products">
                    <ad :item="item" :key="index"></ad>
                  </template>
                </template>
              </ul><br>
            </div>
            <div class="pad-15" v-if="tab==='contact'">
              <div class="row margin-0">
                <div class="col-md-5 col-12 pad-5">
                  <div class="pad-5" v-if="false">
                    <span class="txt-grey"><span class="fa fa-phone"></span> Contact Number</span><br>
                    <p><b>+91 <span v-if="store">{{store.mobile}}</span></b></p>
                  </div>
                  <div class="pad-5" v-if="store && store.email">
                    <span class="txt-grey"><span class="fa fa-envelope"></span> Email Address</span><br>
                    <p><b><span>{{store.email}}</span></b></p>
                  </div>
                  <div class="pad-5" v-if="store && store.data && store.data.address">
                    <span class="txt-grey"><span class="fa fa-map-marker"></span> Address</span><br>
                    <p><b><span>{{store.data.address.address}}, {{store.data.address.city}}, {{store.data.address.state}}, {{store.data.address.pincode}}</span></b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="post-ad-mbl">
      <router-link v-if="$route.name==='Home'||$route.name==='About'||$route.name==='View'" class="btn btn-warning margin-right-20" to="/post-free-ad/">+ Post Free Ad</router-link>
    </div>
  </div>
</template>
<script>
import TimeLabel from '@/components/TimeLabel.vue'
export default {
  components: {
    TimeLabel
  },
  props: ['id'],
  data() {
    return {
      store: null,
      tab: 'products',
      loading_list: true,
      loading_products: true,
      products: null
    }
  },
  created() {
    this.getStoreDetails();
  },
  methods: {
    getStoreDetails() {
      this.loading_list = true;
      this.$cs.store.create({data: {path: this.id}})
      .then(res => {
        this.store = res.data;
        this.loading_list = false;
        this.getProducts(this.store.id);
      })
    },
    getProducts(user=null) {
      this.loading_products = true;
      this.$cs.product.create({
        resource: `${this.$cs.product.api}bulk/`,
        data: {user: user}
      })
      .then(res => {
        this.products = res.data;
        this.loading_products = true;
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.store
  padding 6vh 2vh 0vh
  min-height 100vh
  .store-block
    background-color #ffffff
    border 1px solid #dddddd
    border-radius 2px
    .info-block
      padding 14px
      border-bottom 1px dashed #44
    .detail-block
      min-height 50vh
  .store-menu
    list-style none
    padding 0px
    margin 0px
    display flow-root
    width 100%
    border-bottom 1px solid #dddddd
    li
      padding 10px
      float left
      cursor pointer
      min-width 10%
      text-align center
      font-weight 500
      color #888888
      letter-spacing 0.4px
      &.active
        font-weight 700
        color #000000
.ads-list
    li
      width 24%
      &:hover
        border-color #0594d9
@media (max-width: 767px)
  .store
    padding 1vh 0vh
</style>
